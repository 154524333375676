<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CCard class="card-simple">
      <CCardBody>
        <CRow >
          <CCol sm="12">
            <!--<CustomTabs :active-tab="0">
              <CustomTab :title="$t('label.basicData')">-->
                <CRow class="mt-2">
                  <CCol sm="12" lg="4" class="text-right mt-2 px-2" style="margin-bottom:10px;">
                    <label><b class="text-danger">* </b>{{  $t('label.rifNumber') }}</label>
                  </CCol>
                  <CCol sm="12" lg="2" class="px-2" style="margin-bottom:10px;"><!-- rif -->
                    <CSelect
                      v-uppercase
                      style="width:95px;" 
                      class="w-100"    
                      :is-valid="hasError($v.LetraRif)"
                      :invalid-feedback="errorMessage($v.LetraRif)"
                      v-model="LetraRif"
                      :value.sync="LetraRif"
                      :options="LetraOptions"
                      addLabelClasses="required text-right"
                    />
                  </CCol>
                  <CCol sm="12" lg="4" class="pl-0" style="margin-bottom:10px;"> <!-- rif -->
                    <CInput
                      minlength="9"
                      @blur="$v.$touch()"
                      :placeholder="$t('label.rifNumber')"
                      v-uppercase
                      v-model.trim="$v.ClientRif.$model"
                      ref="RifCliente"
                      maxlength="9"
                      :is-valid="hasError($v.ClientRif)"
                      :invalid-feedback="errorMessage($v.ClientRif)"
                    />
                  </CCol>
      

                  <CCol sm="12" lg="2" class="px-0" style="margin-bottom:10px;">
                        <CButton color="wipe" v-if="editEmpresa" size="sm" class="mr-1" v-c-tooltip="{content: $t('label.clearFields')}" @click="limpiarDatos" >
                            <CIcon name="cil-brush-alt"/>
                        </CButton>
                        <CButton color="edit" size="sm" @click="showTableCollapse = !showTableCollapse" v-c-tooltip="{content: $t('label.companyBranchList')}" >   
                                    {{ textCheck }}
                                  <CIcon :name="iconCheck"/>
                        </CButton>
                  </CCol>
                  
                  <CCol sm="12" style="margin-bottom:10px;">
                    <CInput
                      v-uppercase
                      maxlength="500"
                      v-model.trim="$v.ClientName.$model"
                      :is-valid="hasError($v.ClientName)"
                      :invalid-feedback="errorMessage($v.ClientName)"
                      :label="$t('label.company')"
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                      :placeholder="$t('label.company')"
                    />
                  </CCol>
                  <CCol sm="12" style="margin-bottom:10px;">
                    <CTextarea
                      v-model="ClientAddress"
                      rows="2"
                      v-model.trim="$v.ClientAddress.$model"
                      :is-valid="hasError($v.ClientAddress)"
                      :invalid-feedback="errorMessage($v.ClientAddress)"
                      :label="$t('label.address')"
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                      :placeholder="$t('label.address')"
                    />
                  </CCol>
                  <CCol sm="12" style="margin-bottom:10px;">
                    <CInput
                      v-uppercase
                      :label="$t('label.representative')"
                      addLabelClasses="required text-right"
                      :invalid-feedback="errorMessage($v.Representative)"
                      maxlength="250"
                      v-model.trim="$v.Representative.$model"
                      :is-valid="hasError($v.Representative)"
                      :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                      :placeholder="$t('label.representative')"
                    />
                  </CCol>
               
                  <CCol sm="12" style="margin-bottom:10px;">
                    <CInput
                      v-uppercase
                      v-model="PrimaryPhone"
                      maxlength="15"
                      v-model.trim="$v.PrimaryPhone.$model"
                      :is-valid="hasError($v.PrimaryPhone)"
                      :invalid-feedback="errorMessage($v.PrimaryPhone)"
                      :label="$t('label.mainPhone')"
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                      :placeholder="$t('label.mainPhone')"
                    />
                  </CCol>
                  <CCol sm="12" style="margin-bottom:10px;">
                    <CInput
                      v-uppercase
                      :label="$t('label.secondaryPhone')"
                      addLabelClasses="text-right"
                      maxlength="15"
                      v-model.trim="$v.SecondaryPhone.$model"
                      :is-valid="hasError($v.SecondaryPhone)"
                      :invalid-feedback="errorMessage($v.SecondaryPhone)"
                      :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                      :placeholder="$t('label.secondaryPhone')"
                    />
                  </CCol>
                  <CCol sm="12" style="margin-bottom:10px;">
                    <CInput
                      v-model="PrimaryEmail"
                      maxlength="250"
                      v-model.trim="$v.PrimaryEmail.$model"
                      :is-valid="hasError($v.PrimaryEmail)"
                      :invalid-feedback="errorMessage($v.PrimaryEmail)"
                      :label="$t('label.mainEmail')"
                      addLabelClasses="required text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                      :placeholder="$t('label.mainEmail')"
                    />
                  </CCol>
                  <CCol sm="12" style="margin-bottom:10px;">
                    <CInput
                      v-model="SecondaryEmail"
                      :label="$t('label.secondaryEmail')"
                      addLabelClasses="text-right"
                      maxlength="250"
                      :invalid-feedback="errorMessage($v.SecondaryEmail)"
                      v-model.trim="$v.SecondaryEmail.$model"
                      :is-valid="hasError($v.SecondaryEmail)"
                      :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                      :placeholder="$t('label.secondaryEmail')"
                    />
                  </CCol>

                  <CCol sm="12" style="margin-bottom:10px;" v-if="editEmpresa">
                        <CSelect
                            :label="$t('label.status')"
                            addLabelClasses="required text-right"
                            :horizontal="{label:'col-sm-12 col-lg-4',input:'col-sm-12 col-lg-6'}"
                            v-model="Status"
                            :value.sync="Status"
                            :options="selectOptions"
                            :is-valid="statusSelectColor"
                          />
                  </CCol>
                </CRow>
                <CCollapse :show="showTableCollapse" class="mt-5">
                  <dataTableExtended
                  class="align-center-row-datatable"
                  :items="formatedItems"
                  :fields="fields"
                  :loading="Loading"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination  
                >
                  <template #loading>
                    <loading/>
                  </template>
                  <template #Status="{item}">
                    <td class="center-cell">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Detalle="{item}">
                  <td class="center-cell  py-2">
                      <CButton color="edit" square size="sm" v-c-tooltip="{placement:'top-end',
                      content: $t('label.edit') + ' ' + $t('label.company') }" @click="editDatos(item)">
                        <CIcon name="pencil" />
                      </CButton>
                  </td>
                </template>
                </dataTableExtended>
              </CCollapse>  
              <!--</CustomTab>
            </CustomTabs>-->
          </CCol>
        </CRow>
      </CCardBody>
      <!--<CCardFooter class="text-right">
        <CButton outline color="success" :disabled="desactivado" @click="evaluaStatus" class="mr-1">
          <CIcon name="checkAlt" />&nbsp;
          {{$t('button.accept')}}
        </CButton>
        <CButton color="dark" @click="cerrar">
          <CIcon name="cil-chevron-circle-left-alt"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </CCardFooter>-->
    </CCard>
    <CRow>
      <CCol sm="12" class="d-flex justify-content-end footer">
        <CButton outline color="add" class="m-2"  @click="evaluaStatus">
        <CIcon name="checkAlt"/>&nbsp;
        {{$t('button.accept')}}
        </CButton>
        <CButton color="wipe"  @click="cerrar" class="m-2">
          <CIcon name="x"/>&nbsp;
          {{$t('button.cancel')}}
        </CButton>
      </CCol>
    </CRow>
  </div>  
</template>

<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import ClientValidations from '@/_validations/empresa/clientValidations';
import UpperCase  from '@/_validations/uppercase-directive';
import General from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';


//DATA
function data () {
  return {
    //MODELO
    ClientId: '',
    LetraRif: 'J',
    ClientRif: '',
    ClientName: '',
    ClientAddress: '',
    PrimaryEmail: '',
    SecondaryEmail: '',
    PrimaryPhone: '',
    Representative: '',
    SecondaryPhone: '',
    Status: 1,
    FgStatus: 1,
    //VARIABLES
    image: '',
    Loading: false,    
    datos: null,
    vLoading: false,
    LetraOptions: ['J','G','P','V'],    
    showTableCollapse: false,
    iconoCollapse: 'cil-chevron-top-alt',
    textoCollapse: '',
    items: [],
    editEmpresa: false,
     //VARIABLES
    selectOptions: [
      { 
        value: 1, 
        label: this.$t('label.ACTIVO')
      },
      { 
        value: 0, 
        label: this.$t('label.INACTIVO')
      }
    ],
    
  }
}
//METHOD
function limpiarDatos() {
  this.ClientId='';
  this.LetraRif= 'J';
  this.ClientRif= '';
  this.ClientName= '';
  this.ClientAddress= '';
  this.PrimaryEmail= '';
  this.SecondaryEmail= '';
  this.PrimaryPhone= '';
  this.SecondaryPhone= '';
  this.Status=1;
  this.FgStatus=1;
  this.editEmpresa = false;
  this.$nextTick(() => { this.$v.$reset() })
}

function iconCheck() {

  return this.iconoCollapse = (this.showTableCollapse) ? 'cil-chevron-top-alt' : 'cil-chevron-bottom-alt';
}
function textCheck() {

  return this.textoCollapse = (this.showTableCollapse) ? this.$t("label.close") : this.$t("label.toView");
}

function editDatos(item) {

    this.LetraRif      = item.ClientRif.substr(0,1);
    var RIF            = item.ClientRif;
    this.ClientRif     = RIF.substr(1, (RIF.length - 1));
    this.ClientName    = item.ClientName;
    this.ClientAddress = item.ClientAddress;
    this.Representative= item.Representative;
    this.PrimaryEmail  = item.PrimaryEmail;
    this.SecondaryEmail= item.SecondaryEmail;
    this.PrimaryPhone  = item.PrimaryPhone;
    this.SecondaryPhone= item.SecondaryPhone;
    this.editEmpresa = true;
    this.Status = item.FgActClient ? 1 : 0;
    this.FgStatus = item.FgActClient ? 1:0;
    this.ClientId = item.ClientId;
    this.$v.$touch();
}

function fields(){ 
  return [
    { key: 'Nro', label: '#', filter: false, _style: 'width:1%; text-align:center;' },
    { key: 'ClientRif', label:'RIF', _style:'width:15%', _classes: 'text-uppercase' },
    { key: 'ClientName', label:this.$t('label.company'), _style: 'width: 15%;', _classes:'text-center'},
    { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;', _classes: 'text-uppercase' },
    { key: 'FormatedDate', label: this.$t('label.date'), _style: 'width: 15%;', _classes:'text-center'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width: 10%;', _classes:'text-center'},
    { 
      key: 'Detalle',
      label: '',
      sorter: false,
      filter: false,
      _style:'min-width:45px; width:5%;',
      _classes: 'text-center'
    }
  ]
}


function listarEmpresas () {

  this.loadingTable = true;
  this.$http.get("Client-list", { filter: 'ALL' })
  .then(response => {
     this.items = response.data.data;
    
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.loadingTable = false;
  });
}

function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    Nro: item.Nro,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    FgApplyTablet: item.FgApplyTablet ? this.$t('label.yes') : 'NO',
    _classes: item.Status != 'ACTIVO'? 'table-danger': ''
  }));
} 

function evaluaStatus() {

  if (this.editEmpresa && (this.Status !== this.FgStatus)) {
    this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')+' '+this.ClientName}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel')
    }).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else{

        
        this.guardar();
  } 
}


function guardar() {

  try {
    this.Loading = true;
    this.$v.$touch();

    if (this.$v.$error) {
     this.Loading = false;
     throw this.$t('label.errorsPleaseCheck');

    }
      let res = [];
      let rif = this.LetraRif+this.ClientRif;
      let metodo = '';
      let ruta = '';
      let ClientJson = [];
    
    //ASIGNACION DE VARIABLES PARA GUARDAR
    if (this.editEmpresa) {
      ClientJson = {
              ClientId: this.ClientId,
              ClientName: this.ClientName,
              ClientRif: rif,
              ClientAddress: this.ClientAddress,
              PrimaryPhone: this.PrimaryPhone,
              SecondaryPhone: this.SecondaryPhone,
              PrimaryEmail: this.PrimaryEmail,
              SecondaryEmail: this.SecondaryEmail,
              Representative: this.Representative,
              Status: this.Status,
      };
      metodo = 'PUT';
      ruta = "Client-update";
    } else {
      ClientJson = {
              ClientName: this.ClientName,
              ClientRif: rif,
              ClientAddress: this.ClientAddress,
              PrimaryPhone: this.PrimaryPhone,
              SecondaryPhone: this.SecondaryPhone,
              PrimaryEmail: this.PrimaryEmail,
              SecondaryEmail: this.SecondaryEmail,
              Representative: this.Representative
      };
      metodo = 'POST';
      ruta = "ClientRrhh-insert";
    }
  
    this.$http.ejecutar(metodo, ruta, ClientJson, { root: 'ClientJson' })
    .then(response => {
      res = [...response.data.data];
      this.limpiarDatos();
      this.$notify({
        group: 'container',
        title: '¡Exito!',
        text: res[0].Response,
        type: "success"
      });
      this.Loading= false;
      this.listarEmpresas();
     
    }).catch((err) => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
      this.Loading= false;
    });
    } catch (e) {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: e,
        type: "error"
    });
  }

}

function cerrar() {
  this.limpiarDatos();
  this.$emit('child-refresh',true);
}


export default {
  name: 'add-empresa',
  mixins: [General],
  components:{ 
    CustomTabs,
    CustomTab
  },
  data,
  props: ['value'],
  directives: UpperCase,
  validations: ClientValidations,
  watch: {
    
  },
  methods: {
    limpiarDatos,
    evaluaStatus,
    guardar,
    listarEmpresas,
    cerrar,
    editDatos
  },
  computed: {
    iconCheck,
    textCheck,
    fields, 
    formatedItems,
  }, 
   mounted() {
    this.listarEmpresas();
  },
}
</script>

<style lang="scss">
/*
.alto {
  height: 200px;
}
*/
.footer{
    margin: 0px;
    padding: 0px;
    border-top: 1px solid;
    border-top-color: #d8dbe0;
    left: 0px;
    vertical-align: middle !important;
}
.card-simple{
  border: 0px;
  margin-bottom: 0 !important;
}
</style>