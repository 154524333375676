import { required, maxLength, minLength, email, helpers } from "vuelidate/lib/validators";
import { onlyAlphanumeric, phoneTest, onlyNumber, especiales } from '@/_validations/validacionEspeciales';

const mobileNotReq = (value) => !helpers.req(value) || phoneTest(value);
const emailToLower = (value) => email(value.toLowerCase());

export default () => {
    return {
        LetraRif: { required },
        ClientName: { required, especiales, maxLength: maxLength(500) },
        ClientRif: { required,  onlyNumber, minLength: minLength(9), maxLength: maxLength(50) },
        ClientAddress: { required, onlyAlphanumeric, maxLength: maxLength(500) },
        PrimaryPhone: { required, mobileNotReq, minLength: minLength(11), maxLength: maxLength(15) }, 
        PrimaryEmail: { required, emailToLower },
        SecondaryPhone: { mobileNotReq },
        SecondaryEmail: { emailToLower },
        Representative: { required, onlyAlphanumeric, maxLength: maxLength(250) },
    }
}

